import React from "react";
import { Link } from "react-router-dom";

import { MdBeachAccess, MdFamilyRestroom } from "react-icons/md";
import { TbToolsKitchen3 } from "react-icons/tb";

import Header from "./components/Header";
import Hero from "./components/Hero";
import InfoCard from "./components/InfoCard";
import Carousel from "./components/Carousel";
import ImageGrid from "./components/ImageGrid";
import OwnerRezBooking from "./components/OwnerRezBooking";
import AboutUs from "./components/AboutUs";

import gradbg from "./images/gradbg.jpg";
import star from "./images/star.svg";

import balcony1 from "./images/bigblue/balcony1.jpg";
import beachfront1 from "./images/bigblue/beachfront1.jpeg";
import beachfront2 from "./images/bigblue/beachfront2.jpeg";
import bunk1 from "./images/bigblue/bunk1.jpeg";
import dining1 from "./images/bigblue/dining1.jpeg";
import firstfloorbath from "./images/bigblue/firstfloorbath.jpeg";
import hallwaybath1 from "./images/bigblue/hallwaybath1.jpeg";
import kitchen1 from "./images/bigblue/kitchen1.jpeg";
import kitchen2 from "./images/bigblue/kitchen2.jpeg";
import laundry from "./images/bigblue/laundry.jpeg";
import livingroom1 from "./images/bigblue/livingroom1.jpeg";
import master1 from "./images/bigblue/master1.jpeg";
import masterbath1 from "./images/bigblue/masterbath1.jpeg";
import masterbath2 from "./images/bigblue/masterbath2.jpeg";
import outside2 from "./images/bigblue/outside2.jpeg";
import outside3 from "./images/bigblue/outside3.jpeg";
import queen1 from "./images/bigblue/queen1.jpeg";
import twin1 from "./images/bigblue/twin1.jpeg";

let images = [
  { image: livingroom1, name: "living room" },
  { image: kitchen1, name: "kitchen" },
  { image: kitchen2, name: "kitchen" },
  { image: beachfront2, name: "back yard" },
  { image: master1, name: "master bedroom" },
  { image: queen1, name: "queen bedroom" },
  { image: twin1, name: "double twin bedroom" },
  { image: bunk1, name: "bunk bedroom" },
  { image: masterbath1, name: "master bathroom" },
  { image: masterbath2, name: "master bathroom" },
  { image: firstfloorbath, name: "first floor bathroom" },
  { image: hallwaybath1, name: "hallway bathroom" },
  { image: laundry, name: "laundry room" },
  { image: dining1, name: "dining room" },
  { image: balcony1, name: "first floor balcony" },
  { image: beachfront1, name: "fort morgan peninsula" },
  { image: outside2, name: "parking" },
  { image: outside3, name: "big blue" },
];

const reviews = [
  {
    author: "John P.",
    quote: "Easy to recommend Big Blue - 10 out of 10 for us.",
  },
  {
    author: "Tamera T.",
    quote: "Best part is walking out the door straight to the beach!",
  },
  {
    author: "Laurie E.",
    quote:
      "Well stocked, clean, comfy beds, and the perfect place for 6 friends to reunite!",
  },
  {
    author: "Jennifer W.",
    quote: "Such a relaxing and peaceful place for a perfect vacation.",
  },
  {
    author: "Leslie W.",
    quote:
      "My favorite beach vacation by far. The host was in great communication throughout our stay.",
  },
  {
    author: "Christy C.",
    quote:
      "This property has been renovated beautifully. It had everything we needed.",
  },
  {
    author: "Beau M.",
    quote:
      "As a family we were able to maximize our time on the beach due to the homes close proximity. Look forward to staying in this home again.",
  },
  {
    author: "Pat S.",
    quote: "Oh. My. Word... Big Blue was all we hoped for. Highly recommend!!",
  },
];

const BigBlue = () => {
  return (
    <>
      <Header navOptions={["photos", "reviews", "amenities"]} reserve={true} />
      <main className="main">
        <Hero />
        <section
          className="relative flex flex-col justify-center items-center bg-center bg-cover bg-no-repeat mx-auto"
          style={{
            backgroundImage: `url(${gradbg})`,
          }}
        >
          <div className="mt-12">
            <h2
              className="text-6xl md:text-7xl text-center mb-12"
              id="ourhouse"
            >
              our house
            </h2>
            <InfoCard
              numBeds={4}
              numBaths={3}
              numPeople={13}
              color={"bg-bigblue/70"}
              icons={[
                <MdBeachAccess />,
                <MdFamilyRestroom />,
                <TbToolsKitchen3 />,
              ]}
              mainFeatures={[
                "ocean front duplex",
                "perfect for large groups",
                "well stocked kitchen",
              ]}
            />
          </div>
          <div className="flex flex-col items-center mx-auto mt-12">
            <h2 className="text-6xl md:text-7xl text-center mb-4" id="photos">
              photos
            </h2>
            <Link
              className="text-xl md:text-3xl text-bigblue/80 hover:underline mb-8"
              to="/gallery"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              see all photos
            </Link>
            <ImageGrid images={images} />
          </div>
          <div className="w-[90%] sm:w-[80%] h-full flex flex-col justify-center items-center mt-12">
            <h2 className="text-6xl md:text-7xl text-center mb-12" id="reviews">
              reviews
            </h2>
            <div
              className="flex justify-center max-w-[100%] mb-8"
              aria-label="Rating is 5.0 out of 5"
            >
              <div>
                <img src={star} className="px-2 w-[100%] " alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
            </div>
            <h3 className="text-5xl text-center mb-4">
              5.0 <span className="text-xl">out of</span> 5
            </h3>
            <Link
              className="text-sm md:text-xl text-bigblue/80 hover:underline mb-8"
              to="https://www.vrbo.com/306625ha?pwaDialog=summary-reviews-property-summary-1"
            >
              see all 176 reviews on vrbo
            </Link>
            <Carousel reviews={reviews} />
          </div>
        </section>
        <div className="w-[90%] flex flex-col justify-center items-center mx-auto mt-12">
          <h2 className="text-6xl md:text-7xl mb-4" id="amenities">
            amenities
          </h2>
          <ul className="flex flex-row flex-wrap justify-center mx-auto w-fit h-fit">
            <ul className="text-xl md:text-2xltext-gray-700 p-8">
              <b>bedrooms</b>
              <li className="py-2">king with master bath</li>
              <li className="py-2">king</li>
              <li className="py-2">
                bunk bed (queen, full) with trundle (twin)
              </li>
              <li className="py-2">2 twins</li>
              <li className="py-2">full sleeper sofa</li>
            </ul>
            <ul className="text-xl md:text-2xl text-gray-700 p-8">
              <b>house</b>
              <li className="py-2">wifi</li>
              <li className="py-2">smart TVs (Airplay)</li>
              <li className="py-2">air conditioning</li>
              <li className="py-2">heating</li>
              <li className="py-2">washing machine</li>
              <li className="py-2">drying machine</li>
            </ul>
            <ul className="text-xl md:text-2xl text-gray-700 p-8">
              <b>kitchen</b>
              <li className="py-2">refrigerator</li>
              <li className="py-2">stove</li>
              <li className="py-2">dishwasher</li>
              <li className="py-2">microwave</li>
              <li className="py-2">oven</li>
              <li className="py-2">grill</li>
              <li className="py-2">dishes & utensils</li>
              <li className="py-2">mixing bowls</li>
              <li className="py-2">knife set</li>
              <li className="py-2">coffee maker</li>
              <li className="py-2">air fryer</li>
              <li className="py-2">blender</li>
              <li className="py-2">kettle</li>
              <li className="py-2">toaster</li>
            </ul>
          </ul>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-6xl md:text-7xl mb-12" id="reserve">
              reserve
            </h2>
            <OwnerRezBooking isForBigBlue={true} />
          </div>
        </div>
      </main>
      <footer className="my-4">
        <AboutUs />
      </footer>
    </>
  );
};

export default BigBlue;
