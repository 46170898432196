import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex flex-col w-screen h-screen items-center justify-center">
      <h1 className="bold text-7xl text-bigblue">Oops!</h1>
      <p className="mt-4">Sorry, an unexpected error has occurred:</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
