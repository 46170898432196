import React from "react";
import { Link } from "react-router-dom";

import logo from "../images/rrlogoicon.png";

import Nav from "./Nav";

const Header = ({ navOptions, reserve }) => {
  return (
    <div className="bg-white text-bigblue border-gray-200 border-b fixed top-0 left-0 right-0 w-full flex items-center justify-between flex-wrap p-4 z-[5]">
      <Link
        className="hover:brightness-125"
        to="/"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <img src={logo} alt="logo" width="50" height="50" />
      </Link>
      <Nav navOptions={navOptions} reserve={reserve} />
    </div>
  );
};

export default Header;
