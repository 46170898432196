import React from "react";
import { Link } from "react-router-dom";

import { MdBeachAccess, MdElderly } from "react-icons/md";
import { HiMiniSparkles } from "react-icons/hi2";

import Header from "./components/Header";
import InfoCard from "./components/InfoCard";
import Carousel from "./components/Carousel";
import ImageGrid from "./components/ImageGrid";
import OwnerRezBooking from "./components/OwnerRezBooking";
import AboutUs from "./components/AboutUs";

import gradbg from "./images/gradbg.jpg";
import star from "./images/star.svg";

import balcony2 from "./images/beachhaven/balcony2.JPG";
import balcony3 from "./images/beachhaven/balcony3.JPG";
import beach1 from "./images/beachhaven/beach1.JPG";
import beach2 from "./images/beachhaven/beach2.JPG";
import beachaccess2 from "./images/beachhaven/beachaccess2.jpg";
import beachaccess2webp from "./images/beachhaven/beachaccess2.webp";
import bunkbed1 from "./images/beachhaven/bunkbed1.JPG";
import hallwaybath from "./images/beachhaven/hallwaybath.JPG";
import kitchen2 from "./images/beachhaven/kitchen2.JPG";
import kitchen3 from "./images/beachhaven/kitchen3.JPG";
import livingroom1 from "./images/beachhaven/livingroom1.JPG";
import livingroom2 from "./images/beachhaven/livingroom2.JPG";
import master2 from "./images/beachhaven/master2.JPG";
import masterbath1 from "./images/beachhaven/masterbath1.JPG";
import pool1 from "./images/beachhaven/pool1.JPEG";
import queen1 from "./images/beachhaven/queen1.JPG";
import silverbeach2 from "./images/beachhaven/silverbeach2.JPG";

let images = [
  { image: livingroom1, name: "living room" },
  { image: livingroom2, name: "living room" },
  { image: kitchen2, name: "kitchen" },
  { image: kitchen3, name: "kitchen" },
  { image: master2, name: "master bedroom" },
  { image: masterbath1, name: "master bathroom" },
  { image: queen1, name: "queen bedroom" },
  { image: bunkbed1, name: "bunk bedroom" },
  { image: hallwaybath, name: "hallway bathroom" },
  { image: balcony3, name: "first floor balcony" },
  { image: balcony2, name: "first floor balcony" },
  { image: pool1, name: "Swimming pool" },
  { image: beach1, name: "orange beach" },
  { image: beach2, name: "orange beach" },
  { image: silverbeach2, name: "Silver beach Condominiums" },
];

const reviews = [
  {
    author: "Angela M.",
    quote:
      "The place couldn’t have been any better. I would definitely stay there again!",
  },
  {
    author: "Holly O.",
    quote:
      "Very convenient ground floor access to beach and pool, especially since we had toddlers!",
  },
  {
    author: "Chaz E.",
    quote: "Clean condo, comfortable beds. Kitchen well equipped!",
  },
  {
    author: "Maggie B.",
    quote:
      "The owner is super sweet and quick to message back, and the property has all the amenities you could ever need! You won’t find a better view at the beach!",
  },
  {
    author: "Melissa G.",
    quote:
      "We absolutely loved our stay in this condo. It has been newly remodeled and it was just lovely. ",
  },
];

const BeachHaven = () => {
  return (
    <>
      <main
        className="bg-center bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${gradbg})`,
        }}
      >
        <Header
          navOptions={["photos", "reviews", "amenities"]}
          reserve={true}
        />
        <section className="flex flex-col text-center items-center justify-center mt-[73px] mb-12">
          <div className="relative flex flex-col items-center w-[80%] h-fit ">
            <picture>
              <source type="image/webp" srcset={beachaccess2webp} />
              <img
                className="top-0 h-screen object-cover"
                src={beachaccess2}
              ></img>
            </picture>

            <div className="absolute top-[10%] text-white ">
              <h1 className="text-4xl xsm:text-5xl md:text-7xl lg:text-8xl mb-4">
                Beach Haven
              </h1>
              <p className="text-lg xsm:text-lg md:text-xl lg:text-2xl">
                at Silver Beach Condominiums
              </p>
            </div>
          </div>
        </section>
        <section className="relative flex flex-col justify-center items-center mx-auto">
          <div className="mt-12">
            <h2
              className="text-6xl md:text-7xl text-center mb-12"
              id="ourhouse"
            >
              our condo
            </h2>
            <InfoCard
              numBeds={3}
              numBaths={2}
              numPeople={8}
              color={"bg-bigblue/70"}
              icons={[<MdBeachAccess />, <MdElderly />, <HiMiniSparkles />]}
              mainFeatures={[
                "ocean front access",
                "ground floor unit",
                "newly renovated",
              ]}
            />
          </div>
          <div className="flex flex-col items-center mx-auto mt-12">
            <h2 className="text-6xl md:text-7xl text-center mb-4" id="photos">
              photos
            </h2>
            <Link
              className="text-xl md:text-3xl text-bigblue/80 hover:underline mb-8"
              to="/gallery"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              see all photos
            </Link>
            <ImageGrid images={images} />
          </div>
          <div className="w-[90%] sm:w-[80%] h-full flex flex-col justify-center items-center mt-12">
            <h2 className="text-6xl md:text-7xl text-center mb-12" id="reviews">
              reviews
            </h2>
            <div
              className="flex justify-center max-w-[100%] mb-8"
              aria-label="Rating is 5.0 out of 5"
            >
              <div>
                <img src={star} className="px-2 w-[100%] " alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
              <div>
                <img src={star} className="px-2 w-[100%]" alt="star"></img>
              </div>
            </div>
            <h3 className="text-5xl text-center mb-4">
              5.0 <span className="text-xl">out of</span> 5
            </h3>
            <Link
              className="text-sm md:text-xl text-bigblue/80 hover:underline mb-8"
              to="https://www.vrbo.com/309656ha"
            >
              see all 94 reviews on vrbo
            </Link>
            <Carousel reviews={reviews} />
          </div>
        </section>
        <div className="w-[90%] flex flex-col justify-center items-center mx-auto mt-12">
          <h2 className="text-6xl md:text-7xl mb-4" id="amenities">
            amenities
          </h2>
          <ul className="flex flex-row flex-wrap justify-center mx-auto w-fit h-fit">
            <ul className="text-xl md:text-2xl text-gray-700 p-8">
              <b>bedrooms</b>
              <li className="py-2">king with bath</li>
              <li className="py-2">king</li>
              <li className="py-2">bunk bed (full, queen)</li>
              <li className="py-2">hallway bath</li>
            </ul>
            <ul className="text-xl md:text-2xl text-gray-700 p-8">
              <b>house</b>
              <li className="py-2">wifi</li>
              <li className="py-2">smart TVs (Airplay)</li>
              <li className="py-2">air conditioning</li>
              <li className="py-2">heating</li>
              <li className="py-2">washing machine</li>
              <li className="py-2">drying machine</li>
            </ul>
            <ul className="text-xl md:text-2xl text-gray-700 p-8">
              <b>kitchen</b>
              <li className="py-2">refrigerator</li>
              <li className="py-2">stove</li>
              <li className="py-2">dishwasher</li>
              <li className="py-2">microwave</li>
              <li className="py-2">oven</li>
              <li className="py-2">dishes & utensils</li>
              <li className="py-2">mixing bowls</li>
              <li className="py-2">knife set</li>
              <li className="py-2">coffee maker</li>
              <li className="py-2">air fryer</li>
              <li className="py-2">blender</li>
              <li className="py-2">kettle</li>
              <li className="py-2">toaster</li>
            </ul>
          </ul>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-6xl md:text-7xl mb-12" id="reserve">
              reserve
            </h2>
            <OwnerRezBooking isForBigBlue={false} />
          </div>
        </div>
      </main>
      <footer className="my-4">
        <AboutUs />
      </footer>
    </>
  );
};

export default BeachHaven;
