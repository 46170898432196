import React from "react";

import { useEffect } from "react";

const OwnerRezBooking = ({ isForBigBlue }) => {
  useEffect(() => {
    if (!window.OwnerRez) {
      const script = document.createElement("script");
      script.src = "https://secure.ownerreservations.com/widget.js";
      script.type = "text/javascript";
      script.id = "ownerRez";
      document.body.appendChild(script);
    } else {
      window.OwnerRez.loadDefaultWidgets();
    }
  });

  if (isForBigBlue) {
    return (
      <div
        className="ownerrez-widget"
        data-widgetid="70e6ca84ba494dc9a315a7f179e41b30"
        data-propertyid="ff6de94713524b53a4d917af3be25989"
        data-widget-type="Booking/Inquiry"
      ></div>
    );
  } else {
    // return widget for Beach Haven booking
    return (
      <div
        className="ownerrez-widget"
        data-widgetid="62863600b9c44281bee38c2ff56ba573"
        data-propertyid="78f258031d734e8d8a0b4392d6e5ad32"
        data-widget-type="Beach Haven Booking Widget - Booking/Inquiry"
      ></div>
    );
  }
};

export default OwnerRezBooking;
