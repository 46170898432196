import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";

import BeachHaven from "./BeachHaven.jsx";
import BigBlue from "./BigBlue.jsx";
import ErrorPage from "./components/ErrorPage.jsx";
import Gallery from "./Gallery.jsx";
import Main from "./Main.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/bigblue",
    element: <BigBlue />,
  },
  {
    path: "/beachhaven",
    element: <BeachHaven />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
