import React from "react";
import background from "../images/bigblue/hero1.jpg";
import backgroundwebp from "../images/bigblue/hero1.webp";

import { BsChevronCompactDown } from "react-icons/bs";
import { motion } from "framer-motion";
import BigBlueText from "./BigBlueText";

function scrollToOurHome() {
  var element_to_scroll_to = document.getElementById("chevron");
  element_to_scroll_to.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
}

export default function Hero() {
  return (
    <div id="backgroundDiv" className="relative w-full h-screen">
      <picture>
        <source type="image/webp" srcset={backgroundwebp} />
        <img
          className="top-0 h-screen w-full object-cover"
          src={background}
        ></img>
      </picture>
      <section className="absolute top-0 flex flex-col justify-between items-center bg-cover bg-center w-full h-full min-w-355">
        <div className="w-[100%] md:w-[80%] mt-48 xl:mt-12 p-8 bg-gradient-radial from-blue-400/[80%] via-blue-400/[20%] via-50% to-transparent to-70% bg-opacity-20 rounded-xl">
          <BigBlueText />
        </div>

        <motion.div
          className="mx-auto flex justify-center text-9xl text-white/[55%] hover:text-white"
          initial={{ opacity: 0, scale: 1, y: 0 }}
          animate={{ opacity: 1, scale: 1, y: [0, 3, 0] }}
          transition={{
            y: { repeat: Infinity, duration: 2, ease: "easeInOut" },
            delay: 0,
            duration: 1.5,
          }}
        >
          <BsChevronCompactDown
            className="hover:drop-shadow-2xl"
            id="chevron"
            onClick={scrollToOurHome}
          />
        </motion.div>
      </section>
    </div>
  );
}
