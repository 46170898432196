import React from "react";
import logo from "../images/rrlogoicon.png";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";

const AboutUs = () => {
  return (
    <div className="flex w-[80%] flex-col justify-center mx-auto">
      <div className="h-2 w-full rounded-sm bg-bigblue mb-8"></div>
      <div className="flex w-full flex-row justify-center items-center mx-auto">
        <div className="flex flex-nowrap items-center justify-center">
          <img className="w-[50px] h-[50px]" src={logo} alt="our logo"></img>
          <p className="text-3xl text-bigblue pl-4">
            Rest & Relax <br /> Vacation LLC
          </p>
        </div>
        <div className="flex flex-nowrap justify-center items-center my-2">
          <motion.button
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }}
            whileTap={{ rotate: 10, scale: 0.9, transition: 0.05 }}
          >
            <a
              href="https://www.instagram.com/restandrelaxvacation/"
              target="_blank"
            >
              <FaInstagram className="text-bigblue h-[30px] w-[30px] mx-4" />
            </a>
          </motion.button>
          <motion.button
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.2 },
            }}
            whileTap={{ rotate: 10, scale: 0.9, transition: 0.05 }}
          >
            <a
              href="https://www.youtube.com/@veronicamuehring9617/videos"
              target="_blank"
            >
              <FaYoutube className="text-bigblue h-[30px] w-[30px]" />
            </a>
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
