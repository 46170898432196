import React from "react";
import { Link } from "react-router-dom";

import ImageGrid from "./components/ImageGrid";

import { IoMdArrowRoundBack } from "react-icons/io";

import beachfront3 from "./images/bigblue/beachfront3.jpg";
import kitchen3 from "./images/bigblue/kitchen3.jpg";
import kitchen4 from "./images/bigblue/kitchen4.jpg";
import kitchen5 from "./images/bigblue/kitchen5.jpg";
import kitchen6 from "./images/bigblue/kitchen6.jpg";
import livingroom2 from "./images/bigblue/livingroom2.jpg";
import livingroom3 from "./images/bigblue/livingroom3.jpg";
import master2 from "./images/bigblue/master2.jpg";
import bunk2 from "./images/bigblue/bunk2.jpg";
import hallwaybath2 from "./images/bigblue/hallwaybath2.jpg";
import hallwaybath3 from "./images/bigblue/hallwaybath3.jpg";
import balcony1 from "./images/bigblue/balcony1.jpg";
import beachfront1 from "./images/bigblue/beachfront1.jpeg";
import beachfront2 from "./images/bigblue/beachfront2.jpeg";
import bunk1 from "./images/bigblue/bunk1.jpeg";
import dining1 from "./images/bigblue/dining1.jpeg";
import firstfloorbath from "./images/bigblue/firstfloorbath.jpeg";
import hallwaybath1 from "./images/bigblue/hallwaybath1.jpeg";
import kitchen1 from "./images/bigblue/kitchen1.jpeg";
import kitchen2 from "./images/bigblue/kitchen2.jpeg";
import laundry from "./images/bigblue/laundry.jpeg";
import livingroom1 from "./images/bigblue/livingroom1.jpeg";
import master1 from "./images/bigblue/master1.jpeg";
import masterbath1 from "./images/bigblue/masterbath1.jpeg";
import masterbath2 from "./images/bigblue/masterbath2.jpeg";
import outside2 from "./images/bigblue/outside2.jpeg";
import outside3 from "./images/bigblue/outside3.jpeg";
import queen1 from "./images/bigblue/queen1.jpeg";
import twin1 from "./images/bigblue/twin1.jpeg";

import beachHaven_balcony1 from "./images/beachhaven/balcony1.JPG";
import beachHaven_balcony2 from "./images/beachhaven/balcony2.JPG";
import beachHaven_balcony3 from "./images/beachhaven/balcony3.JPG";
import beachHaven_beach1 from "./images/beachhaven/beach1.JPG";
import beachHaven_beach2 from "./images/beachhaven/beach2.JPG";
import beachHaven_beachaccess1 from "./images/beachhaven/beachaccess1.JPEG";
import beachHaven_bunkbed1 from "./images/beachhaven/bunkbed1.JPG";
import beachHaven_grill from "./images/beachhaven/grill.JPEG";
import beachHaven_hallway from "./images/beachhaven/hallway.JPG";
import beachHaven_hallwaybath from "./images/beachhaven/hallwaybath.JPG";
import beachHaven_kitchen1 from "./images/beachhaven/kitchen1.JPG";
import beachHaven_kitchen2 from "./images/beachhaven/kitchen2.JPG";
import beachHaven_kitchen3 from "./images/beachhaven/kitchen3.JPG";
import beachHaven_laundry from "./images/beachhaven/laundry.JPG";
import beachHaven_livingroom1 from "./images/beachhaven/livingroom1.JPG";
import beachHaven_livingroom2 from "./images/beachhaven/livingroom2.JPG";
import beachHaven_livingroom3 from "./images/beachhaven/livingroom3.JPG";
import beachHaven_master1 from "./images/beachhaven/master1.JPG";
import beachHaven_master2 from "./images/beachhaven/master2.JPG";
import beachHaven_masterbath1 from "./images/beachhaven/masterbath1.JPG";
import beachHaven_pool1 from "./images/beachhaven/pool1.JPEG";
import beachHaven_pool2 from "./images/beachhaven/pool2.JPG";
import beachHaven_queen1 from "./images/beachhaven/queen1.JPG";
import beachHaven_queen2 from "./images/beachhaven/queen2.JPG";
import beachHaven_silverbeach1 from "./images/beachhaven/silverbeach1.JPG";
import beachHaven_silverbeach2 from "./images/beachhaven/silverbeach2.JPG";

const bigBluePhotos = [
  { image: livingroom1, name: "living room" },
  { image: kitchen1, name: "kitchen" },
  { image: kitchen2, name: "kitchen" },
  { image: beachfront2, name: "back yard" },
  { image: master1, name: "master bedroom" },
  { image: queen1, name: "queen bedroom" },
  { image: twin1, name: "double twin bedroom" },
  { image: bunk1, name: "bunk bedroom" },
  { image: masterbath1, name: "master bathroom" },
  { image: masterbath2, name: "master bathroom" },
  { image: firstfloorbath, name: "first floor bathroom" },
  { image: hallwaybath1, name: "hallway bathroom" },
  { image: laundry, name: "laundry room" },
  { image: dining1, name: "dining room" },
  { image: balcony1, name: "first floor balcony" },
  { image: beachfront1, name: "fort morgan peninsula" },
  { image: outside2, name: "parking" },
  { image: outside3, name: "big blue" },
  { image: beachfront3, name: "fort morgan peninsula" },
  { image: kitchen3, name: "kitchen" },
  { image: kitchen4, name: "kitchen" },
  { image: kitchen5, name: "kitchen" },
  { image: kitchen6, name: "kitchen" },
  { image: livingroom2, name: "living room" },
  { image: livingroom3, name: "living room" },
  { image: master2, name: "master bedroom" },
  { image: bunk2, name: "bunk bedroom" },
  { image: hallwaybath2, name: "master bathroom" },
  { image: hallwaybath3, name: "hallway bathroom" },
];

const beachHavenPhotos = [
  { image: beachHaven_balcony1, name: "balcony" },
  { image: beachHaven_balcony2, name: "balcony" },
  { image: beachHaven_balcony3, name: "balcony" },
  { image: beachHaven_beach1, name: "beach" },
  { image: beachHaven_beach2, name: "beach" },
  { image: beachHaven_beachaccess1, name: "beach access" },
  { image: beachHaven_bunkbed1, name: "bunk bed" },
  { image: beachHaven_grill, name: "grill" },
  { image: beachHaven_hallway, name: "hallway" },
  { image: beachHaven_hallwaybath, name: "hallway bath" },
  { image: beachHaven_kitchen1, name: "kitchen" },
  { image: beachHaven_kitchen2, name: "kitchen" },
  { image: beachHaven_kitchen3, name: "kitchen" },
  { image: beachHaven_laundry, name: "laundry" },
  { image: beachHaven_livingroom1, name: "living room" },
  { image: beachHaven_livingroom2, name: "living room" },
  { image: beachHaven_livingroom3, name: "living room" },
  { image: beachHaven_master1, name: "master bedroom" },
  { image: beachHaven_master2, name: "master bedroom" },
  { image: beachHaven_masterbath1, name: "master bathroom" },
  { image: beachHaven_pool1, name: "pool" },
  { image: beachHaven_pool2, name: "pool" },
  { image: beachHaven_queen1, name: "queen bedroom" },
  { image: beachHaven_queen2, name: "queen bedroom" },
  { image: beachHaven_silverbeach1, name: "silver beach condominiums" },
  { image: beachHaven_silverbeach2, name: "silver beach condominiums" },
];

const Gallery = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full mx-auto">
      <div className="flex justify-center w-full">
        <div className="flex-1">
          <Link
            className="block w-fit text-bigblue hover:text-white hover:bg-bigblue text-4xl sm:text-5xl p-2 rounded-br-2xl"
            to={"/"}
          >
            <IoMdArrowRoundBack />
          </Link>
        </div>

        <h1 className="text-white text-center bg-bigblue text-4xl sm:text-7xl p-8 rounded-b-xl">
          Gallery
        </h1>
        <div className="flex-1"></div>
      </div>
      <div className="flex flex-col justify-center items-center text-bigblue text-2xl sm:text-5xl rounded-2xl mt-12 mx-auto">
        <h2 className="p-4" id="BigBlue">
          Big Blue
        </h2>
        <ImageGrid images={[]} morePhotos={bigBluePhotos} />

        <h2 className="p-4 mt-12">Beach Haven</h2>
        <ImageGrid images={[]} morePhotos={beachHavenPhotos} />
      </div>
    </div>
  );
};

export default Gallery;
