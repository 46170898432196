import React from "react";
import { MdBeachAccess, MdFamilyRestroom } from "react-icons/md";
import { TbToolsKitchen3 } from "react-icons/tb";

const InfoCard = ({
  numBeds,
  numBaths,
  numPeople,
  color,
  icons,
  mainFeatures,
}) => {
  return (
    <div
      className={`h-fit w-fit flex flex-col flex-wrap justify-center items-center p-3 text-gray-100 ${color} shadow-xl rounded-3xl`}
    >
      <div className="h-[100%] border-b-2 border-gray-100/[50%]">
        <ul className="h-[100%] flex flex-row flex-wrap justify-center items-center">
          <li className="flex justify-start items-center md:text-3xl p-1 xsm:p-4 sm:p-8">
            <span className="text-4xl sm:text-5xl md:text-6xl bold text-white pr-2">
              {numBeds}
            </span>{" "}
            BED
          </li>
          <li className="flex justify-start items-center md:text-3xl p-1 xsm:p-4 sm:p-8">
            <span className="text-4xl sm:text-5xl md:text-6xl bold text-white pr-2">
              {numBaths}
            </span>{" "}
            BATH
          </li>
          <li className="flex justify-start items-center md:text-3xl p-1 xsm:p-4 sm:p-8">
            <span className="text-4xl sm:text-5xl md:text-6xl bold text-white pr-2">
              {numPeople}
            </span>{" "}
            PEOPLE
          </li>
        </ul>
      </div>
      <div className="text-gray-100">
        <ul>
          <li className="flex justify-start items-center px-1 py-2 text-base xsm:text-lg sm:text-xl md:text-3xl xsm:px-2 sm:py-3 sm:px-4">
            <div className="mr-2">{icons[0]}</div>
            {mainFeatures[0]}
          </li>
          <li className="flex justify-start items-center px-1 py-2 text-base xsm:text-lg sm:text-xl md:text-3xl xsm:px-2 sm:py-3 sm:px-4">
            <div className="mr-2">{icons[1]}</div>
            {mainFeatures[1]}
          </li>
          <li className="flex justify-start items-center px-1 py-2 text-base xsm:text-lg sm:text-xl md:text-3xl xsm:px-2 sm:py-3 sm:px-4">
            <div className="mr-2">{icons[2]}</div>
            {mainFeatures[2]}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoCard;
