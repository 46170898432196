import React, { useState } from "react";

import { HiMenu } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";

const Nav = ({ navOptions, reserve }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="flex w-1/3 justify-end">
        <div className="hidden w-full md:flex items-center justify-end">
          {navOptions.map((navOption) => (
            <a
              href={`#${navOption}`}
              className="text-xl m-2 hover:brightness-125"
            >
              {navOption}
            </a>
          ))}
          {reserve && (
            <a
              href="#reserve"
              className="text-xl text-white bg-bigblue rounded-xl px-2 m-2 hover:brightness-125"
            >
              reserve
            </a>
          )}
        </div>
        <div className="md:hidden flex items-center text-3xl mx-2">
          <button onClick={toggleNavbar}>
            {isOpen ? <IoCloseOutline /> : <HiMenu />}
          </button>
        </div>
      </nav>
      {isOpen && (
        <div className="flex flex-col items-center basis-full">
          {navOptions.map((navOption) => (
            <a
              href={`#${navOption}`}
              className="text-xl m-2 hover:brightness-125"
              onClick={toggleNavbar}
            >
              {navOption}
            </a>
          ))}
          {reserve && (
            <a
              href="#reserve"
              className="text-xl text-white bg-bigblue rounded-xl px-2 m-2 hover:brightness-125"
              onClick={toggleNavbar}
            >
              reserve
            </a>
          )}
        </div>
      )}
    </>
  );
};

export default Nav;
