import { useState } from "react";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export default function ImageCarousel({ images, clicked, modalOpen }) {
  const [currentIndex, setCurrentIndex] = useState(images.indexOf(clicked));

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    setCurrentIndex(isFirstSlide ? images.length - 1 : currentIndex - 1);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    setCurrentIndex(isLastSlide ? 0 : currentIndex + 1);
  };

  function handleArrowKeyPress(event) {
    switch (event.key) {
      case "ArrowLeft": // left arrow
        prevSlide();
        break;
      case "ArrowRight": // right arrow
        nextSlide();
        break;
      default:
        break;
    }
  }

  return (
    <div
      className="relative w-full flex items-center justify-center rounded-3xl"
      onKeyDown={handleArrowKeyPress}
    >
      <div className="overflow-hidden border-8 top-0">
        <div
          className="flex items-center transition ease-in-out duration-300"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {images.map((i, index) => {
            return (
              <div className="flex-shrink-0 relative justify-center w-full h-auto">
                <img className="w-full h-auto" src={i.image} alt={i.name} />
              </div>
            );
          })}
        </div>
        <div className="group">
          {/* Buttons */}
          <div className="absolute top-0 h-full w-full flex justify-between items-center text-3xl">
            <div
              className="w-[30%] h-[80%] flex flex-col justify-center cursor-pointer"
              onClick={prevSlide}
            >
              <button
                className={`relative group-hover:text-white/70 text-white/30 text-8xl `}
                autoFocus
              >
                <BsChevronLeft className="absolute left-0 top-[50%]" />
              </button>
            </div>
            <div
              className="w-[30%] h-[80%] flex flex-col justify-center cursor-pointer"
              onClick={nextSlide}
            >
              <button
                className={`relative group-hover:text-white/70 text-white/30 text-8xl `}
              >
                <BsChevronRight className="absolute right-0 top-[50%]" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
