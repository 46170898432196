import React from "react";
import { useState } from "react";

import { motion } from "framer-motion";

export default function RentalCard({ name, image, houseType, location }) {
  const [imageLoading, setImageLoading] = useState(true);
  const [pulsing, setPulsing] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
    setPulsing(false);
  };

  return (
    <div
      className={`${
        pulsing ? "animate-pulseGray bg-gray-100" : "bg-white"
      } relative w-full h-full flex flex-col items-start rounded-xl drop-shadow-md hover:drop-shadow-xl p-4 md:p-8`}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { duration: 0.2 } }}
      >
        <img
          className="w-full h-auto rounded-lg"
          onLoad={imageLoaded}
          src={image}
          alt={`our ${houseType}, ${name}`}
        />
        <p className="text-3xl sm:text-4xl md:text-5xl mt-2 sm:mt-4 md:mt-6">
          {name}
        </p>
        <p className="text-lg sm:text-2xl md:text-3xl text-secondary mt-1 sm:mt-2 md:mt-4">
          {houseType} in {location}
        </p>
      </motion.div>
    </div>
  );
}
