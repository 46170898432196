import React from "react";

import { Link } from "react-router-dom";

import bigblue from "./images/bigblue/big blue icon.jpg";
import beachhaven from "./images/beachhaven/beach haven icon.jpg";
import ownerphoto from "./images/ownerphoto.jpg";

import AboutUs from "./components/AboutUs";
import Header from "./components/Header";
import RentalCard from "./components/RentalCard";

const navOptions = ["rentals", "about", "contact"];
const Main = () => {
  return (
    <div className="bg-gray-50">
      <Header navOptions={navOptions} reserve={false} />
      <main className="flex flex-col items-center justify-center mx-auto w-[80%]">
        <h2 className="text-6xl md:text-7xl mb-12 mt-24" id={navOptions[0]}>
          rentals
        </h2>
        <div className="flex flex-wrap lg:flex-nowrap justify-center">
          <Link
            className="basis-full lg:basis-1/2 lg:mb-0 mb-8 mx-8"
            to="/bigblue"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <RentalCard
              image={bigblue}
              name="Big Blue"
              houseType="Beach house"
              location="Fort Morgan"
            />
          </Link>
          <Link
            className="basis-full lg:basis-1/2 mx-8"
            to="/beachhaven"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <RentalCard
              image={beachhaven}
              name="Beach Haven"
              houseType="Condominium"
              location="Orange Beach"
            />
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center text-xl md:text-3xl text-center text-secondary mx-auto mt-12 w-[90%]">
          <h2
            className="text-6xl md:text-7xl text-black mb-12"
            id={navOptions[1]}
          >
            about us
          </h2>
          <p className="text-black">
            Rest and Relax Vacation LLC is a family-owned business providing
            beach front vacation experiences since 2002.
          </p>
          <br></br>
          <p>
            "The business began when I wanted to find a place where my family
            could create memories of our time together. When my kids were young,
            we always preferred to stay in a home rather than a hotel as it was
            easier to prepare our own food. Now that my kids are all grown, I
            hope future guests will be able to create wonderful memories as
            well.{" "}
          </p>
          <br></br>
          <p>
            I always appreciate a well furnished home that is kid friendly, easy
            to cook in, and has comfortable beds. I strive to treat our guests
            how I like to be treated."
          </p>
          <br></br>
          <img
            className="w-[30%] md:w-[20%] rounded-full mb-2"
            src={ownerphoto}
          ></img>
          <p className="text-2xl md:text-4xl text-black"> - Veronica </p>
          <p className="text-lg md:text-2xl text-black">
            <i>Owner</i>
          </p>
        </div>
        <div className="flex flex-col items-center justify-center mx-auto mt-12 w-[80%]">
          <h2 className="text-6xl md:text-7xl mb-8" id={navOptions[2]}>
            contact
          </h2>
          <p className="text-xl md:text-3xl text-black mb-8">
            <a href="mailto: veronica@restandrelaxvacation.com">
              veronica@restandrelaxvacation.com
            </a>
          </p>
        </div>
      </main>
      <footer className="my-4">
        <AboutUs />
      </footer>
    </div>
  );
};

export default Main;
