import React from "react";

const ImageGridItem = ({ imageItem }) => {
  return (
    <div className="relative hover:drop-shadow-xl">
      <img
        className="w-full h-full"
        src={imageItem.image}
        alt={imageItem.name}
        loading="lazy"
      />
    </div>
  );
};

export default ImageGridItem;
