import React from "react";
import { motion } from "framer-motion";

const variants = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (custom) => {
    const delay = 0.1 + custom * 0.3;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const defaultStrokeWidth = 50;

const BigBlueText = () => {
  return (
    <div className="w-full">
      <motion.svg
        width="100%"
        height="100%"
        viewBox="0 0 5460 1877"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        animate="visible"
      >
        <g filter="url(#filter0_d_72_133)">
          {/*first B*/}
          <motion.path
            initial="hidden"
            animate="visible"
            variants={variants}
            custom={1}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={defaultStrokeWidth}
            fill="none"
            d="M13.655 177.604C68.4914 159.943 -63.936 213.126 201.299 169.115C466.534 125.104 791.029 119.992 1087.74 118.17 C1176.88 117.623 1228.26 143.727 1252.66 174.754C1306.09 242.71 1165.54 304.372 1116.69 331.576C933.988 433.326 742.66 514.136 539.672 569.921C547.206 569.921 554.74 569.918 562.274 569.921C637.784 569.957 713.296 569.664 788.804 570.102C933.595 570.941 1077.68 580.07 1219.56 611.116C1253 618.434 1287.28 625.213 1318.47 638.565 C1346.38 650.509 1345.3 679.732 1317.45 692.724C1263.62 717.836 1208.95 741.365 1153.69 763.195C1024.45 814.258 895.855 867.411 764.638 912.909C680.548 942.066 592.811 961.628 505.672 980.78C434.287 996.469 361.304 1004.82 289.242 1017.62 L233.434 1024.3 C199.739 1026.32 169.17 1028.22 138.595 1029.99C109.44 1031.67 80.2729 1034.25 51.1226 1034.1C44.2578 1034.07 37.4386 1024.7 30.5984 1019.62C35.8618 1012.89 39.7262 1003.02 46.6171 999.96C89.2028 981.034 132.291 963.185 175.677 946.162C183.4 943.132 195.898 942.257 201.485 946.534C207.394 951.058 207.681 962.925 211.718 975.672"
          />

          {/*first B vertical line*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={2}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={defaultStrokeWidth}
            variants={variants}
            d="M311.485 160 L311.485 205.326 C326.907 460.712 340.853 713.165 282.563 960.534 L261.582 1086.88C257.655 1096.75 251.655 1122.25 227.155 1122.25"
          />

          {/*i*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={3}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={38}
            variants={variants}
            d="M1445.11,525.659c40.198,24.795,-1.03,11.872,3.942,108.662c3.551,359.097,4.502,373.953,0,402.924c0,24.585,-32.854,25.368,-34.062,0c-1.612,-34.004,-1.157,-68.246,-0.469,-102.37c7.181,-120.524,12.173,-199.892,10.029,-300.554c-2.017,-94.23,-8.281,-126.452,20.56,-108.662z"
          />

          {/*i dot*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={5.5}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={30}
            variants={variants}
            d="M1450.66,382.925c31.52,-9.566,27.56,14.556,29.18,31.957c7.03,75.917,-111.32,11.821,-29.18,-31.957z"
          />

          {/*g*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={4}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={60}
            variants={variants}
            d="M1849.87,553.08592c-32.81,15.699,-64.44,33.998,-95.84,52.462c-70.24,41.292,-108.12,71.706,-160.23,171.445c-66.14,178.761,-24.15,196.972,39.91,188.369c88.11,-11.831,160.18,-87.998,226.86,-142.184c144.07,-117.08,166.53,-178.236,161.92,34.689c-4.68,216.363,-7.42,433.263,-17.25,649.473l0,0c-4.11,90.55,7.55,204.72,-105.15,210.81c-56.29,3.04,-113.05,-2.2,-169.58,-4.46c-91.14,-3.64,-178.79,-25.17,-265.22,-52.75c-298.96,-95.41,-595.542,-210.17,-909.326,-245.22c-159.309,-17.79,-223.809,-15.68,-245.309,-13.47c-39.724,4.1,-76.41,27.33,-104.672,38.32"
          />

          {/*second B*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={5}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={60}
            variants={variants}
            d="M2305.81 236.2c54.836,-17.661,-77.591,35.522,187.644,-8.489c265.235,-44.011,589.73,-49.123,886.441,-50.945c89.14,-0.547,140.52,25.557,164.92,56.584c53.43,67.956,-87.12,129.618,-135.97,156.822c-182.702,101.75,-374.03,182.56,-577.018,238.345c7.534,0,15.068,-0.003,22.602,0c75.51,0.036,151.022,-0.257,226.53,0.181c144.791,0.839,288.876,9.968,430.756,41.014c33.44,7.318,67.72,14.097,98.91,27.449c27.91,11.944,26.83,41.167,-1.02,54.159c-53.83,25.112,-108.5,48.641,-163.76,70.471c-129.24,51.063,-257.835,104.216,-389.052,149.714c-84.09,29.157,-171.827,48.719,-258.966,67.871c-71.385,15.689,-144.368,24.04,-216.43,36.84l-55.808,6.68c-33.695,2.02,-64.264,3.92,-94.839,5.69c-29.155,1.68,-58.322,4.26,-87.472,4.11c-6.865,-0.03,-13.684,-9.4,-20.525,-14.48c5.264,-6.73,9.128,-16.6,16.019,-19.66c42.586,-18.926,85.674,-36.775,129.06,-53.798c7.723,-3.03,20.221,-3.905,25.808,0.372c5.909,4.524,6.196,16.391,10.233,29.138"
          />

          {/*second B vertical line*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={6}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={defaultStrokeWidth}
            variants={variants}
            d="M2603,253c15.422,255.386,29.368,507.839,-28.922,755.208l-20.981,126.346c-3.927,9.87,-9.927,35.37,-34.427,35.37"
          />

          {/*l stroke*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={7}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={38}
            variants={variants}
            d="M3725,111.0213c 0.3185,24.74726 14.3093,-9.739493 14.5396,15.027203 3.003,324.30448 -14.7109,709.35467 -3.0715,1032.92322 1.0483,29.2528 15.6222,27.1699 16.7098,-1.0831 8.4552,-219.49454 6.1939,-440.00326 11.256,-659.8148 3.0311,-131.61987 4.1868,-260.24951 4.1868,-390.521193 0,-16.2791 -0.9866,-27.676676 -16.4276,-26.742625 -15.3576,0.929422 -27.1931,12.251858 -27.1931,30.210369z"
          />

          {/*u*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={8}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={38}
            variants={variants}
            d="M3875.01,700.508c 2.3565,-21.98863 42.5675,-19.10858 40.7429,-1.10544 2.196,26.08492 -37.3296,230.59436 -33.6757,403.68611 17.5542,117.6361 183.8057,59.9817 204.3396,-10.1809 26.5812,-54.8372 63.3158,-347.99471 75.749,-379.3201 3.2917,-8.30659 -10.6178,1.7766 -9.976,-10.29262 -2.3977,-20.91883 40.5718,-28.04123 33.3742,-8.27581 -40.0001,154.57132 -35.5671,378.96873 -105.2927,451.38433 -88.2888,62.7531 -213.0318,39.1405 -214.4347,-15.9898 -9.4671,-62.725 -5.2723,-176.17936 6.5421,-262.21836 6.5055,-44.86868 20.0068,-111.09858 18.1546,-155.8321 1.2837,-11.11245 -16.7061,-0.74376 -15.5233,-11.85531"
          />

          {/*last e*/}
          <motion.path
            initial="hidden"
            animate="visible"
            custom={9}
            stroke="white"
            strokeDasharray="0 1"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={38}
            variants={variants}
            d="M 4307.45,704.055c 7.09,-16.771 19.24,-15.441 34.53,-6.254 23.72,14.245 81.32,43.24 86.5,46.202l 45.95,17.007c 58.8,26.91 136.32,35.76 193.35,20.78 83.68,-21.98 172.65,-64.56 236.99,-123.432 54.49,-49.858 32.0083,-114.99178 -112.92,-96.524 -42.7539,6.56297 -95.19,26.42 -150.59,59.977 -55.39,33.557 -115.22,89.274 -166.83,139.196l -38.01,31.615c -43.9534,46.44202 -83.8553,94.27072 -118.26,149.632 -165.8,259.326 224.74,279.596 396.19,273.556 205.96,-7.25 370,-19.13 555.23,-110.95 39.5784,-15.5363 60.4749,7.4195 43.23,17.14 -324.8315,131.1227 -767.0485,173.7308 -995.7994,43.2677 -40.92,-28.24 -59.8798,-47.9449 -53.1878,-137.6384 23.6879,-101.86323 132.3624,-199.35542 155.6766,-224.63371 47.481,-39.84934 207.4409,-183.90771 257.8956,-203.38569"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_72_133"
            x="0"
            y="-0.000244141"
            width="5459.68"
            height="1876.14"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="29" dy="37" />
            <feGaussianBlur stdDeviation="9.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.396078 0 0 0 0 0.560784 0 0 0 0 0.94902 0 0 0 0.43 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_72_133"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_72_133"
              result="shape"
            />
          </filter>
        </defs>
      </motion.svg>
    </div>
  );
};

export default BigBlueText;
