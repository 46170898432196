import { motion } from "framer-motion";
import Backdrop from "./Backdrop";

const dropIn = {
  hidden: { scale: 1, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { duration: 0.1 } },
  exit: { scale: 1, opacity: 0 },
};

const Modal = ({ children, handleClose }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        className="max-w-[80%] h-fit"
        key="modal"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {children}
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
