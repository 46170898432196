import { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { RxDot, RxDotFilled } from "react-icons/rx";
import seashell from "../images/seashell.svg";

export default function Carousel({ reviews }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    setCurrentIndex(isFirstSlide ? reviews.length - 1 : currentIndex - 1);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === reviews.length - 1;
    setCurrentIndex(isLastSlide ? 0 : currentIndex + 1);
  };

  function fillCarouselWithQuote(author, quote) {
    if (quote.length >= 86) {
      return (
        <div className="basis-full shrink-0 mt-2 p-8" key={author}>
          <p className="text-center text-xl sm:text-2xl">{quote}</p>
          <p className="text-center text-sm mt-2">- {author}</p>
        </div>
      );
    } else {
      return (
        <div className="basis-full shrink-0 mt-2 p-8" key={author}>
          <p className="text-center text-2xl sm:text-3xl">{quote}</p>
          <p className="text-center text-sm mt-2">- {author}</p>
        </div>
      );
    }
  }

  return (
    <div
      className="relative min-h-[300px] w-full flex flex-col items-center justify-center bg-contain bg-top bg-repeat-space rounded-3xl z-0"
      style={{
        backgroundImage: `url(${seashell})`,
      }}
    >
      <div className="overflow-hidden top-0 w-full h-full">
        <div
          className="flex items-center transition ease-out duration-40"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {reviews.map((r) => {
            return fillCarouselWithQuote(r.author, r.quote);
          })}
        </div>
        <div className="group">
          {/* Buttons */}
          <div className="absolute top-0 h-full w-full flex justify-between items-center text-3xl">
            <button
              className={`relative group-hover:text-black text-black/30 text-lg w-[30%] h-[80%]`}
              onClick={prevSlide}
            >
              <BsChevronLeft className="absolute left-0 top-[40%]" />
            </button>
            <button
              className={`relative group-hover:text-black text-black/30 text-lg w-[30%] h-[80%]`}
              onClick={nextSlide}
            >
              <BsChevronRight className="absolute right-0 top-[40%]" />
            </button>
          </div>
          {/* Dots */}
          <div className="absolute bottom-1 w-full flex justify-center">
            {reviews.map((r, reviewIndex) => (
              <div
                key={r.author}
                onClick={() => setCurrentIndex(reviewIndex)}
                className={`group-hover:text-black text-black/30 text-2xl cursor-pointer`}
              >
                {currentIndex === reviewIndex ? <RxDotFilled /> : <RxDot />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
