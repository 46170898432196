import React from "react";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import Modal from "./Modal";
import ImageGridItem from "./ImageGridItem";
import ImageCarousel from "./ImageCarousel";

let isMobileSize = window.innerWidth < 768;
window.addEventListener("resize", (event) => {
  isMobileSize = window.innerWidth < 768;
});

function ImageGrid({ images, morePhotos }) {
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const [clickedImageItem, setClickedImageItem] = useState(images[0]);
  function imageGridItemClickHandler(e, imageItem) {
    modalOpen ? close() : open();
    setClickedImageItem(imageItem);
    return;
  }

  const [imageArray, setImageArray] = useState(
    morePhotos != null ? images.concat(morePhotos) : images
  );

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 p-4">
        {imageArray.map((i) => {
          return (
            <div
              onClick={(e) => {
                imageGridItemClickHandler(e, i);
              }}
            >
              <ImageGridItem imageItem={i} />
            </div>
          );
        })}
      </div>
      <AnimatePresence initial={false} mode="wait">
        {!isMobileSize && modalOpen && (
          <Modal
            className="fixed top-0 left-0 right-0 bottom-0 z-10"
            handleClose={close}
          >
            <ImageCarousel
              images={imageArray}
              clicked={clickedImageItem}
              modalOpen={modalOpen}
            />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ImageGrid;
